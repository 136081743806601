<div class="div-curved">
    <div style="text-align: center; font-size: 1.2em;">
            {{productName}} <small style="color: #e0e0ff;">Bulk upload</small>
            <div><small *ngIf="params.master_policy_no">{{declarations? 'Declarations' : 'Certificates'}}</small></div>
    </div>
    <div class="svg-curved">
        <svg viewBox="0 0 500 150" style="height: 100%; width: 100%;" preserveAspectRatio="none">
          <linearGradient id="gradient"><stop offset="0%" class="curve-stop-0"/><stop offset="100%" class="curve-stop-100"/></linearGradient>
          <path d="M-0.85,137.65 C135.72,178.12 336.06,-110.03 500.84,85.35 L500.00,0.00 L0.00,0.00 Z" style="stroke: none; fill: url(#gradient);"></path>
        </svg>
    </div>
    
</div>

<div class="bulk-container" *ngIf="!session">
    <div class="new-session">
	<div style="padding: 2em;margin-left: -50px;"  *ngIf="declare_marine">
			<mat-radio-group  [(ngModel)]="selected">
            <mat-radio-button value="basic" style="padding-right: 1em;">Basic</mat-radio-button>
            <mat-radio-button value="detail" style="padding-right: 1em;">Detail</mat-radio-button>
			 
        </mat-radio-group>
		</div>
	<!-- 	<form [formGroup]="policyForm" (ngSubmit)="onSubmit()"> -->
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: 0.5em 3em; align-items: center;" *ngIf="declare_marine">
          	 
			<div *ngIf="selected == 'basic'">
                <mat-form-field>
					<mat-label>Voyage</mat-label>
					<mat-select [(ngModel)]="voyage" name="voyage">
						<mat-option  *ngFor="let vt of voyage_type" [value]="type" [value]="vt">{{vt}}</mat-option>
					</mat-select>
				<!-- 	 <mat-error *ngIf="policyForm.get('voyage').touched && userForm.get('name').invalid">
							<span *ngIf="policyForm.get('voyage').errors.required">Name is required.</span>
			 
				</mat-error> -->
				  </mat-form-field> 
					
            </div>
			 <div >
                <mat-form-field>
					<mat-label>Declaration Type</mat-label>
					<input matInput  [(ngModel)]="dtype" placeholder="Declaration Type"  >
					
				  </mat-form-field>
<!-- 					<div *ngIf="policyForm.get('dtype').errors.required">Name is required.</div> -->				  
            </div>
			<div *ngIf="declare_marine">
                <mat-form-field>
                    <mat-label>Period</mat-label>
                   <mat-select [(ngModel)]="dperiod" name="Dperiod">
						<mat-option  *ngFor="let dp of decl_period"  [value]="dp">{{dp}}</mat-option>
					</mat-select>
                </mat-form-field>
            </div>
			 <div>
                <mat-form-field>
                    <mat-label>Year</mat-label>
                    <input matInput [(ngModel)]="year" placeholder="Year"   maxlength="4"  >
                </mat-form-field>
				
				</div>
			
			 <div *ngIf="selected == 'basic'">
                <mat-form-field >
                    <mat-label>Sum Insured</mat-label>
                    <input matInput [(ngModel)]="sumins" placeholder="Sum Insured">
                </mat-form-field>
            </div>
			<div *ngIf="selected == 'detail'" >
               <button mat-button (click)="download_template()" style="color: #2727ce;text-decoration: underline;">Download Template</button>
            </div>
			<div class="file-upload">
                Upload Declaration (csv/xlsx)
                <input type="file" #fileInput id="file" (click)="fileInput.value = null" value="" [accept]="'csv,xlsx'" (change)="onUpload_decl($event)" />
            </div>
				 <div >
                <button  mat-button ibutton (click)="submit_decl()">Submit</button>

            </div>
			</div>
		<!-- </form> -->
			 
			   <div style="display: grid; grid-template-columns: 1fr 2fr; gap: 0.5em 3em; align-items: center;" *ngIf="!declare_marine">
			 <div >
                <mat-form-field>
                    <mat-label>Unique Reference (optional)</mat-label>
                    <input matInput [(ngModel)]="refId" placeholder="Unique Reference ID">
                </mat-form-field>
            </div>
            <div>
               <button mat-button (click)="download_template()">Download Template</button>
            </div>

            <div class="file-upload">
                Upload new data (csv)
                <input type="file" #fileInput id="file1" (click)="fileInput.value = null" value="" [accept]="'csv'" (change)="onUpload($event)" />
            </div>
            <div>
                <div>All date fields should be in the format (DD-MM-YYYY).</div><br>
				<div>Following data will be added to the uploaded rows (if not found)</div>
                <span class="constants" *ngFor="let item of params | keyvalue">{{item.key}} : {{item.value}}</span>
            </div>
    
        </div>
    </div>


    <div style="padding-top: 3em;" >
        Previously uploaded sessions
        <mat-table [dataSource]="sesSource" #sessort="matSort" matSort>
            <ng-container *ngFor="let col of sescolumns; let i = index" [matColumnDef]="col">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{col | capitalize}}</mat-header-cell>
                <mat-cell *matCellDef="let row" [attr.data-label]="col" (click)="selectSession(row.session_id)">
                    <div style="cursor: pointer; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" [title]="row[col.name]">{{row[col] | generic2: col.pipe :col.pipe_args}}</div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="sescolumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: sescolumns;" [class]="'rec-status-' + row.status"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons #sespage></mat-paginator>

        <!-- <div *ngFor="let s of sessions" class="session">
            <div style="cursor: pointer;" (click)="selectSession(s.session_id)">{{s.ref_id}}</div>
            <div>{{s.started_at}}</div>
            <div>{{s.ended_at}}</div>
            <div>{{session_status[s.status]}}</div>
        </div> -->
    </div>

</div>

<div class="bulk-container" [style.width]="conf.layout.width+'px'"  *ngIf="session">
    <div class="header">
        <div>
            <h3>Session: {{session.session_id}} [{{session_status[session.status]}}] {{refId}}</h3>
        </div>
        <div>
            <span>
                <mat-form-field>
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Filter string" #input>
                </mat-form-field>
            </span>
            <span *ngIf="(session.status == 5 || session.status == 2)">
                <button mat-button ibutton (click)="downloadExcel()">Download</button>
            </span>
        </div>
        <div>
           
        </div>
    </div>
    <mat-table [dataSource]="recSource" #recsort="matSort" matSort>
        <ng-container *ngFor="let col of conf.columns; let i = index" [matColumnDef]="col.disp">
            <ng-template [ngIf]="col.type==='count'">
                <mat-header-cell *matHeaderCellDef mat-sort-header  style="max-width: 48px;"> {{col.disp | capitalize}} </mat-header-cell>
                <mat-cell *matCellDef="let row" [attr.data-label]="col" style="max-width: 48px;">{{row.i+1}}</mat-cell>
            </ng-template>
    

            <ng-template [ngIf]="col.type==='action'">
                <mat-header-cell *matHeaderCellDef mat-sort-header  style="max-width: 48px;"> {{col.disp | capitalize}} </mat-header-cell>
                <mat-cell *matCellDef="let row" [attr.data-label]="col" style="max-width: 48px;">
                    <button mat-icon-button [matMenuTriggerFor]="actionMenu" [matMenuTriggerData]="{row: row}" aria-label="">
                    <mat-icon>more_vert</mat-icon>
                    </button> 
                </mat-cell>
            </ng-template>

            <ng-template [ngIf]="col.type!=='action' && col.type!=='count'">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{col.disp | capitalize}}</mat-header-cell>
                <mat-cell *matCellDef="let row" [attr.data-label]="col.disp">
                    <!-- <span class="mobile-label">{{col.disp}}</span> -->
                    <div style="cursor: pointer; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" [title]="row[col.name]">{{row[col.name] | generic2: col.pipe :col.pipe_args}}</div>
                </mat-cell>
            </ng-template>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;" [class]="'rec-status-' + row.status"></mat-row>
        <mat-menu #actionMenu>
            <ng-template matMenuContent let-row="row">
                <button mat-menu-item *ngFor="let act of row.actions" (click)="menuClicked(act, row)">
                    <mat-icon>{{act.icon}}</mat-icon>{{act.name}}
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons #recpage></mat-paginator>


    <div>
        Total {{counts.total}} records found. 
        <span style="background-color: #800; color: #FFF; padding: 1em;" *ngIf="counts.errors>0">
            {{counts.errors}} errors were found</span>
    </div>


    <div *ngIf="(session.status == 0 || session.status == 2) && counts.errors <= 0">
        <button mat-button ibutton (click)="start()">Start processing</button>
    </div>

    <div *ngIf="session.status == 2">
        <button mat-button ibutton (click)="cancel()">Cancel processing</button>
    </div>

    <!-- <span *ngIf="(session.status == 5 || session.status == 2)">
        <button mat-button ibutton (click)="downloadExcel()">Download</button>
    </span> -->

    <div *ngIf="session.status == 1">
        <button mat-button ibutton (click)="pause()">Pause processing</button>
    </div>

    <!--<div *ngIf="session.status == 0 || counts.errors>0" style="padding: 1em; width: 200px;">
        <div class="file-upload">
            Replace data
            <input type="file" [accept]="'csv,xlsx'" (change)="onUpload($event, true)" />
        </div>
    </div>-->    
    
    
    <!-- <div *ngIf="session.status == 5">
        Processing completed
    </div>
    <div *ngIf="session.status == 4">
        Processing failed
    </div>
    <div *ngIf="session.status == 3">
        Session cancelled
    </div> -->

    <div *ngIf="progress" class="progress">
        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 1em; background-color: #EAEAFF; padding: 1em 1em; border-radius: 6px;">
            <div style="text-align: right;" *ngIf="progress.start">Started at : </div> <div>{{progress.start | datetime}}</div>
            <div style="text-align: right;" *ngIf="!progress.start">Not started yet</div>
            <div style="text-align: right;" *ngIf="progress.end"> Completed at : </div><div>{{progress.end | datetime}}</div>
            <div style="text-align: right;" *ngIf="session.status==1"><mat-spinner [diameter]="30"></mat-spinner></div>
        </div>
        
        <div class="progress-item">
            <div>To be processed</div><div style="text-align: right;">{{progress.n}}</div>
            <div>In progress</div><div style="text-align: right;">{{progress.i}}</div>
            <div>UW Referral</div><div style="text-align: right;">{{ counts.nstpCount }}</div>
            <div>Completed</div><div style="text-align: right;">{{progress.c}}</div>
            <div>Errors</div><div style="text-align: right;">{{progress.e}}</div>
            <div>Failed</div><div style="text-align: right;">{{progress.f}}</div>            
        </div>
    </div>

    <div style="cursor: pointer;" (click)="switchToSessions()">
        <mat-icon>chevron_left</mat-icon> Back
    </div>
</div>